import React, { Component } from 'react';
import {
    Alert, Button, Spinner, Table,
} from 'react-bootstrap';
import { PageStatus } from 'enums';
import {Show} from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import {exportToExcel} from "../../Utils/ExportToExcel";
import {AuthAPI, SamplesAPI, SecAPI, SurveysAPI} from "../../API";
import {CountriesAPI} from "../../API/CountriesAPI";
import Select from 'react-select';
import {Form} from "../Samples/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import {reduxForm} from "redux-form";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {FormValue} from "../My Settings/MySettings";
import PanelistTable from "./PanelistTable";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    data: any[],
    id?: string | null,
    filteredData: any[],
    filters: {
        id: '',
        email: '',
        phoneNumber: '',
        gender: '',
        startAge: 0,
        endAge: 100,
        isActive: '',
        registrationStarts: '',
        registrationEnds: '',
        states: [],
        cities: [],
        tiers: [],
        surveys: [],
        sec: [],
        selectedStatesOption: [],
        selectedStatesIdOption: [],
        selectedCitiesOption: [],
        selectedTiersOption: [],
        loginType: {},
        osOptionArray:any[],
    },
    pageSize : any,
    currentPageNumber : any,
    loaderloading : any,
    deviceTargetOptions: any[],
    selectedDeviceOptions: any[],
    selectedOsOptions: any[],
};

class AllPanelists extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            data: [],
            id: null,
            filteredData: [],
            selectedStatesOption: [],
            selectedStatesIdOption: [],
            selectedCitiesOption: [],
            selectedTiersOption: [],
            selectedSurveysOption: [],
            selectedSecOption: [],
            statesOptions: [],
            citiesOptions: [],
            tiersOptions: [],
            surveysOptions: [],
            secOptions: [],
            filters: {
                id: '',
                email: '',
                phoneNumber: '',
                gender: '',
                startAge: '',
                endAge: '',
                isActive: '',
                registrationStarts: '',
                registrationEnds: '',
                states: [],
                cities: [],
                tiers: [],
                surveys: [],
                sec: [],
                loginType: {
                    "desktop": false,
                    "tablet": false,
                    "mobile": false,
                    "windows": false,
                    "mac": false,
                    "tablet_android": false,
                    "tablet_ios": false,
                    "mobile_android": false,
                    "mobile_ios": false,
                  },
                osOptionArray:[],
            },
            cityReadonly : true,
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : '',
            loaderloading: false,
            deviceTargetOptions: [{ label: "Desktop", value: 'desktop' }, { label: "Tablet", value: 'tablet' }, { label: "Mobile", value: 'mobile' }],
            selectedDeviceOptions: [],
            selectedOsOptions: [],
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        this.fetchList()
        this.fetchMasterList()
    }

    fetchList(): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.allPanelists( this.state.filters, this.state.pageSize, this.state.currentPageNumber))
            .then((users) => {
                let responseData: any = users; 
                this.setState({ data: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage,  status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    fetchMasterList(): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => Promise.all([
                    CountriesAPI.getAllStates(1000),
                ]
            ))
            .then(([statesList]) => {
                const stateOptions = statesList.map((item) => ({
                    label: item.name,
                    value: item.name,
                    id: item.id,
                }));

                this.setState({
                    statesOptions: stateOptions,
                });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    isShowDetailModal(): boolean {
        return this.state.formType === MODAL_TYPES.DETAILS
            || this.state.formType === MODAL_TYPES.DELETE;
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };

    applyFilters = () => {
        if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
        this.setState({currentPageNumber : 1});
    }


    clearFilter = async () => {
        await this.setState({ filters: {
                id: '',
                email: '',
                phoneNumber: '',
                gender: '',
                startAge: '',
                endAge: '',
                isActive: '',
                registrationStarts: '',
                registrationEnds: '',
                states: [],
                cities: [],
                tiers: [],
                surveys: [],
                sec: [],
                loginType: {
                    "desktop": false,
                    "tablet": false,
                    "mobile": false,
                    "windows": false,
                    "mac": false,
                    "tablet_android": false,
                    "tablet_ios": false,
                    "mobile_android": false,
                    "mobile_ios": false,
                  },
                osOptionArray:[],
            },
                selectedStatesOption: [],
                selectedStatesIdOption: [],
                selectedCitiesOption: [],
                selectedTiersOption: [],
                selectedSurveysOption: [],
                selectedSecOption: [],
                cityReadonly : true,
                pageSize : 10,
                currentPageNumber : 1,
                totalResponseData : '',
                totalResponsePages : '',
                selectedDeviceOptions: [],
                selectedOsOptions: [],
        })
        this.fetchList();
    }

    handleStateChange = async (selectedStatesOption) => {       
        const states = selectedStatesOption.map(option => option.value);        
        const statesId = selectedStatesOption.map(option => option.id);  
        const cityReadonlyVal = states.length > 0 ? false : true;      
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    states
                },
            }),
        );
        await this.setState({ selectedStatesOption, selectedStatesIdOption : statesId });

        // this code is written to get statewise cities. Then show cities in the city options.
        if (this.state.selectedStatesIdOption.length > 0) { 
            Promise.resolve()
            .then(() => Promise.all([
                    CountriesAPI.getAllCitiesByStateId(this.state.selectedStatesIdOption),
                ]
            ))
            .then(([citiesList]) => {
                const citiesOptions = citiesList.map((item) => ({
                    label: item.name,
                    value: item.name,
                }));

                this.setState({
                    citiesOptions: citiesOptions,
                    cityReadonly : cityReadonlyVal
                });
            });
        }else{
            this.setState({
                citiesOptions: [],
                cityReadonly : cityReadonlyVal,
                selectedCitiesOption : []
            });
            this.setState(
                (prevState) => ({
                    filters: {
                        ...prevState.filters,
                        cities : [],
                        selectedCitiesOption : []
                    },
                }),
            );
        }
    };

    handleCityChange = async (selectedCitiesOption) => {
        const cities = selectedCitiesOption.map(option => option.value);
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    cities
                },
            }),
        );
        this.setState({ selectedCitiesOption });
    };

    async handleExport(){
        this.setState({ loaderloading: true });
        try {
            const recievedData = await AuthAPI.allPanelists( this.state.filters, 'All', this.state.currentPageNumber);
            exportToExcel(recievedData.data, 'allPanelist');
        } catch (error) {
            console.error("Error exporting data:", error);
        }finally {
            this.setState({ loaderloading: false });
        }
    };
    
  handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
  handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };

  componentDidUpdate(prevProps: {}, prevState: State) {
      if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
  };
      
    handleDeviceFilterChange = async (selectedDeviceOptions) => {
        let checkLastElement = selectedDeviceOptions[selectedDeviceOptions.length-1];
        this.setState((prevValues) => {
            const updatedFilters = {
                "desktop": false,
                "tablet": false,
                "mobile": false,
                "windows": false,
                "mac": false,
                "tablet_android": false,
                "tablet_ios": false,
                "mobile_android": false,
                "mobile_ios": false,
            };
            let osOptionArray: { label: string, value: string }[] = [];
            let prevSelectedOptionsArray: { label: string, value: string }[] = [...prevValues.selectedOsOptions];
            selectedDeviceOptions.forEach((element) => {
                if (element.value === "desktop") {
                    osOptionArray.push({ label: "Windows", value: 'windows' });
                    osOptionArray.push({ label: "Mac", value: 'mac' });
                    if(checkLastElement.label == "Desktop" && prevSelectedOptionsArray.find(ele=> ele.value == "windows") == undefined && prevSelectedOptionsArray.find(ele=> ele.value == "mac") == undefined){
                        prevSelectedOptionsArray.push({ label: "Windows", value: 'windows' });
                        prevSelectedOptionsArray.push({ label: "Mac", value: 'mac' });
                    }
                }
                if (element.value === "tablet") {
                    osOptionArray.push({ label: "Tablet Android", value: 'tablet_android' });
                    osOptionArray.push({ label: "Tablet iOS", value: 'tablet_ios' });
                    if(checkLastElement.label == "Tablet" && prevSelectedOptionsArray.find(ele=> ele.value == "tablet_android") == undefined && prevSelectedOptionsArray.find(ele=> ele.value == "tablet_ios") == undefined){
                        prevSelectedOptionsArray.push({ label: "Tablet Android", value: 'tablet_android' });
                        prevSelectedOptionsArray.push({ label: "Tablet iOS", value: 'tablet_ios' });
                    }
                }
                if (element.value === "mobile") {
                    osOptionArray.push({ label: "Mobile Android", value: 'mobile_android' });
                    osOptionArray.push({ label: "Mobile iOS", value: 'mobile_ios' });
                    if(checkLastElement.label == "Mobile" && prevSelectedOptionsArray.find(ele=> ele.value == "mobile_android") == undefined && prevSelectedOptionsArray.find(ele=> ele.value == "mobile_ios") == undefined){
                        prevSelectedOptionsArray.push({ label: "Mobile Android", value: 'mobile_android' });
                        prevSelectedOptionsArray.push({ label: "Mobile iOS", value: 'mobile_ios' });
                    }
                }
                if (updatedFilters[element.value] != undefined) {
                    updatedFilters[element.value] = true;
                } 
            });
            if(selectedDeviceOptions.find(ele=> ele.value == "desktop") == undefined){
                prevSelectedOptionsArray = prevSelectedOptionsArray.filter((ele) => (ele.value !== "windows" && ele.value !== "mac"));
            }
            if(selectedDeviceOptions.find(ele=> ele.value == "tablet") == undefined){
                prevSelectedOptionsArray = prevSelectedOptionsArray.filter(ele => ele.value !== "tablet_android" && ele.value !== "tablet_ios");
            }
            if(selectedDeviceOptions.find(ele=> ele.value == "mobile") == undefined){
                prevSelectedOptionsArray = prevSelectedOptionsArray.filter(ele => ele.value !== "mobile_android" && ele.value !== "mobile_ios");
            }
            prevSelectedOptionsArray.forEach(element=> updatedFilters[element.value] = true);
            return {
                filters: {
                    ...prevValues.filters,
                    loginType: updatedFilters,
                    osOptionArray
                },
                selectedDeviceOptions,
                selectedOsOptions: prevSelectedOptionsArray,
            };
        });
    };

    handleOsFilterChange = (selectedOsOptions)=>{
        this.setState((prevValues) => {
            const selectedDeviceOptions:any = [];
            let dummyArr:any[] = [];
            const updatedFilters = { 
                "desktop": false,
                "tablet": false,
                "mobile": false,
                "windows": false,
                "mac": false,
                "tablet_android": false,
                "tablet_ios": false,
                "mobile_android": false,
                "mobile_ios": false,
            };
            selectedOsOptions.forEach((element)=>{ 
                updatedFilters[element.value] = true;
                if(element.value === "windows" || element.value === "mac"){ updatedFilters["desktop"] = true; selectedDeviceOptions.push({ label: "Desktop", value: 'desktop' }) } 
                if(element.value === "tablet_android" || element.value === "tablet_ios"){ updatedFilters["tablet"] = true; selectedDeviceOptions.push({ label: "Tablet", value: 'tablet' })} 
                if(element.value === "mobile_android" || element.value === "mobile_ios"){ updatedFilters["mobile"] = true; selectedDeviceOptions.push({ label: "Mobile", value: 'mobile' })} 
                dummyArr = selectedDeviceOptions.filter( ele => updatedFilters[ele.value]);
                dummyArr = [...new Map(selectedDeviceOptions.map(item => [`${item.label}-${item.value}`, item])).values()];
            });
            return {
                filters: {
                    ...prevValues.filters,
                    loginType: updatedFilters,
                },
                selectedOsOptions,
                selectedDeviceOptions : dummyArr,
            };
        });
    }

    render() {
        const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;        
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Panelists</h4>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='mb-3'>Filter</div>

                    <form>
                        <div className="row mt-2">
                            <div className="col">
                                <label>User ID</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="id"
                                       value={filters.id}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Email</label>
                                <input type="email"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="email"
                                       value={filters.email}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Phone Number</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="phoneNumber"
                                       value={filters.phoneNumber}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="text">States</label>
                                <Select
                                    name='state'
                                    id='state'
                                    onChange={this.handleStateChange}
                                    value={this.state.selectedStatesOption}
                                    isMulti
                                    required
                                    options={this.state.statesOptions}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="text">Cities</label>
                                <Select
                                    isDisabled={this.state.cityReadonly}
                                    name='cities'
                                    id='cities'
                                    onChange={this.handleCityChange}
                                    value={this.state.selectedCitiesOption}
                                    isMulti
                                    required
                                    options={this.state.citiesOptions}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor='gender'>Gender*</label>
                                <select
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        height: '40px',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        transition:
                                            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                    }}
                                    name='gender'
                                    id='gender'
                                    required
                                    onChange={this.handleFilterChange}
                                    value={this.state.filters.gender}
                                >
                                    <option value=''>--Choose--</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                    <option value='Other'>Other</option>
                                </select>
                            </div>
                            <div className="col">
                                <label htmlFor="fromAge">Min Age*</label>
                                <input
                                    className="form-control"
                                    id="fromAge"
                                    type="number"
                                    name="startAge"
                                    value={filters.startAge}
                                    onChange={this.handleFilterChange}
                                    placeholder="Enter start Age"
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="fromAge">Max Age*</label>
                                <input
                                    className="form-control"
                                    id="toAge"
                                    type="number"
                                    name="endAge"
                                    value={filters.endAge}
                                    onChange={this.handleFilterChange}
                                    placeholder="Enter end Age"
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="registrationStarts">Registration Start Date*</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="registrationStarts"
                                    name="registrationStarts"
                                    onChange={this.handleFilterChange}
                                    value={filters.registrationStarts}
                                    placeholder="Enter here"
                                    required
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="registrationEnds">Registration End Date*</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="registrationEnds"
                                    name="registrationEnds"
                                    onChange={this.handleFilterChange}
                                    value={filters.registrationEnds}
                                    placeholder="Enter here"
                                    required
                                />
                            </div>
                        </div>
                        <div className='card mt-3' style={{backgroundColor:"#e8e8e8"}}>
                            <div className='row p-3'>
                                <div className="col">
                                    <label>Select Device</label>
                                    <Select
                                        name='loginType'
                                        id='loginType'
                                        onChange={(e) => {this.handleDeviceFilterChange(e)}}
                                        value={this.state.selectedDeviceOptions}
                                        isMulti
                                        required
                                        options={this.state.deviceTargetOptions}
                                    />
                                </div>
                                <div className="col">
                                    <label>Select OS</label>
                                    <Select
                                        name='loginType'
                                        id='loginType'
                                        onChange={(e) => {this.handleOsFilterChange(e)}}
                                        value={this.state.selectedOsOptions}
                                        isMulti
                                        required
                                        options={this.state.filters?.osOptionArray}
                                        isDisabled={this.state.filters?.osOptionArray?.length < 1}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                        <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Panelists</button>
                        <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
                        <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
                    </div>


                </div>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>
                        <Show when={this.state.formType === MODAL_TYPES.CREATE}>
                            <Form
                                show={this.state.formType === MODAL_TYPES.CREATE}

                                onClose={() => this.setState({
                                    formType: MODAL_TYPES.NONE,
                                })}
                                onSubmit={(id) => {
                                    this.setState({
                                        formType: MODAL_TYPES.NONE,
                                    });
                                }}
                            />
                        </Show>
                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.filteredData.length}>
                                No data is available
                            </Alert>
                        </Show>

                        <Show when={!!this.state.filteredData.length}>
                            <div className='mb-2'>  
                                <ShowDataAccording2PageSize 
                                currentPageSize={pageSize} 
                                currentPage={currentPageNumber}
                                sendPageSize={this.handlePageSizeChange}
                                showTotalData={totalResponseData} 
                                sendLastPage={totalResponsePages}
                                />
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                                {this.state.loaderloading && (
                                    <img style={{ height: "415px", }}
                                        src="../loadernew.gif"
                                        alt="Loading..."
                                    />
                                )}
                            </div>
                            <PanelistTable filteredData={this.state.filteredData} role={this.props.role} sendCurrentPage={currentPageNumber} sendCurrentPageSize={pageSize}/>
                            <div>
                                <PaginationNumbering 
                                    currentPage={currentPageNumber} 
                                    returnPagenumber={this.handlePageNumberChange}
                                    showTotalData={totalResponseData} 
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}


const AllPanelistsRedux = reduxForm<FormValue, any>({
    form: 'AllPanelists',
})(AllPanelists);

const mapStateToProps = (state: { adminUser: { adminUser: { phoneNumber: any, email: any, userId: any; token: any; loading: any; error: any; role: any, language:any }; }; }) => {
    return {
        userId: state.adminUser.adminUser.userId,
        role: state.adminUser.adminUser.role,
        phoneNumber: state.adminUser.adminUser.phoneNumber,
        email: state.adminUser.adminUser.email,
        isLoading: state.adminUser.adminUser.loading,
        error: state.adminUser.adminUser.error,
        language: state.adminUser.adminUser.language,
    };
};

const AllPanelistsWithRouter = withRouter(connect(mapStateToProps)(AllPanelistsRedux));

export { AllPanelistsWithRouter as AllPanelists };
