import axios from 'axios';
import * as _ from 'lodash';
import { store } from './reducers';
import {baseApiURL} from "./Utils/urls";
import {encryptData} from "./Helper/EncryptionDecryption";
import {decryptData} from "./Helper/EncryptionDecryption";


const api = axios.create({
  baseURL: baseApiURL,
});

api.interceptors.request.use((config) => {
  const state = store.getState();
// console.log(state,"line 13");
const uniquetokennew = state.adminUser.adminUser.apiToken; 
  const language = _.get(state, 'adminUser.adminUser.language', null);
  const configCopy = { ...config };
  if(configCopy.data && configCopy.data != undefined){
    configCopy.data.userAuthId = state.adminUser.adminUser.userId;
    configCopy.data.encryptiondta = true;
    configCopy.data = {token: encryptData(configCopy.data)};
  }
  if (uniquetokennew) {
    // console.log(uniquetokennew, "uniquetokennew 15");
    configCopy.headers['Authorization'] = `${uniquetokennew}`// Corrected the format of the Authorization header
  }
  if (!!language) {
    configCopy.headers['language'] = `${language}`;
  } else {
    configCopy.headers['language'] = 'en';
  }

  return configCopy;
});

// ================================
// api.interceptors.request.use(config => {
//   const state = store.getState();
//   const uniquetokennew = state.adminUser.adminUser.apiToken; // Corrected the path to the token
//   console.log(state, uniquetokennew, "uniquetokennew");
//   if (uniquetokennew) {
//     console.log(uniquetokennew, "uniquetokennew 15");
//     config.headers.Authorization = `${uniquetokennew}`; // Corrected the format of the Authorization header
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });
// =================================

api.interceptors.request.use((config) => {
  return config;
});




api.interceptors.response.use((res) => {
  res.data = decryptData(res.data);
  // console.log("api response = ", res);
  return res;
}, (error) => {
  // console.log("response error = ", error);
  const errorResponse = _.get(error, 'response.data.message', null);
  if (errorResponse) {
    throw new Error(errorResponse);
  }
  // localStorage.clear();
  // location.href = "/";
  throw new Error(error.message);
});

export { api };
