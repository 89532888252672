import * as _ from 'lodash';

import { api } from '../axiosConfig';
import { newsLettersURL } from "../Utils/urls";

export class NewsLetterAPI {
    static getAll(
        limit: any,
        currentPageNumber: number,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${newsLettersURL}/getAll/${limit}/${currentPageNumber}`, {})
        .then((res) => {
            return _.get(res, 'data', []);
        });
    }

    static create(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/create`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static bulkcreate(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/bulkcreate`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static createtempleate(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/createtempleate`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getalltempleates(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/getalltempleates`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getonetempleates(id: any): Promise<any> {
        return api.post(`${newsLettersURL}/getonetempleates/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getOne(
        id: string
    ): Promise<any[]> {
        return api.post(`${newsLettersURL}/getOne/${id}`, {})
        .then((res) => {
            return _.get(res, 'data.data', {});
        });
    }

    static update(id: string, payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/update/${id}`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static deleteOne(id: string): Promise<any> {
        return api.post(`${newsLettersURL}/delete/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static createSample(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/create-newsletter-sample`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }
    static appInbox(id: string): Promise<any> {
        return api.post(`${newsLettersURL}/getAllInAppNotification/100000/1/${id}`, {})
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }
    static updateNotification(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/updateNotification`, payload)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }
    static deleteAll(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/deleteAll`, payload)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }
    static deletetempleates(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${newsLettersURL}/deletetempleates`, payload)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }
}
