import React, { useState, useEffect, useRef } from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import { authLogin, languageChange, languageChangeOption } from "./auth.actions";
import { Assets, PageStatus } from 'enums';
import { LoadingSpinner } from "../../Layout/LoadingSpinner";
import "./Login.css";
import { Show } from "../../Layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import GoogleSignIn from "./googleSignin";
import FacebookSignIn from "./facebookSignin";
import { MobileLogin } from "./MobileLogin";
import Language from "../../Languages/Login/content.json"
import { Helmet } from "react-helmet";
import { ForgetPasswordEmailWithState } from "./ForgetPasswordEmailForm";
// import SimpleCaptcha from "./Capcha";
import GoogleReCaptcha from "./GoogleRecaptcha";
import { registerDict } from 'Languages/RegistrationTranslations';
import signupindiapollsHeader from '../../assets/img/logoen-indiapolls.png';
import headerImage from '../../assets/img/sign-in-create-account.png';
import facebookImage from '../../assets/img/facebook2.png';
import signUpImg from '../../assets/img/forwardarrow.png';
import arrowDownImg from '../../assets/img/arrowdown.png';
import hindiMainHeaderLogo from '../../assets/img/IndiaPolls-Hindi-Logo-without-BG.png';
import hindiLoginImg from '../../assets/img/hinditextnew.png';
// import GridContainer from "../Grid/GridContainer";

function Login(props) {
  const dispatch = useDispatch();
  const [state, setState] = useState({ isVisible: false });
  const [username, setUsername] = useState({ username: null });
  const [password, setPassword] = useState({ password: null });
  const [isPasswordReset, setResetPassword] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [referralId, setReferralId] = useState('');
  const [showGoogleSignIn, setShowGoogleSignIn] = useState(false);
  const [showFacebookSignIn, setShowFacebookSignIn] = useState(false);
  const [captcha, setCaptcha] = useState(true);
  const [showHideSelectBoxValue, setShowHideSelectBoxValue] = useState(false);
  const checkDropdownClick = useRef(null);
  const history = useHistory();
  const { invalid, pristine, submitting } = props;
  const pageContents = props.language === 'hi' ? Language.LoginHindi : Language.LoginEnglish
  const lang = props.language ?? 'en';

  const handleContinueWithGoogleClick = () => {
    setShowGoogleSignIn(true);
  };

  const handleContinueWithFacebookClick = () => {
    setShowFacebookSignIn(true);
  };



  useEffect(() => {
    const url = window.location.href;
    let lang = url.split('=')[1];
    if (lang !== undefined) {
      let urlLang = lang == 'hi' ? 'hi' : 'en';
      setTimeout(() => {
        dispatch(languageChange(urlLang))
      }, 800);
    }
  }, [])

  useEffect(() => {
    const hash = window.location.hash;
    const referralIdMatch = hash.match(/referralId=([^&]*)/);
    const referralId = referralIdMatch ? referralIdMatch[1] : null;
    setReferralId(referralId)
  }, []);

  const onSubmit = () => {
    props.authLogin(
      username.username,
      password.password,
      'password',
      props.language,
      history
    );
  }


  const handleClick = () => {
    if (referralId) {
      const queryParams = {
        referralId: referralId,
      };
      const queryString = Object.keys(queryParams)
        .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
        .join('&');
      const loginUrl = `/auth/signup?${queryString}`;
      history.push(loginUrl)
    } else {
      history.push('/auth/signup')
    }
  };

  const responseFacebook = (info) => {
    console.log('responseFacebook---->', info)
  }

  // const languageChangeOptions = (info) => {
  //   dispatch(languageChange(info.target.value));
  // }

  const handleCaptchaValidation = response => {
    console.log(response)
  }

  const clickOnSelectBox = () => { setShowHideSelectBoxValue(true); }

  const selectDropdownValue = (val) => {
    setShowHideSelectBoxValue(false);
    if (lang != val) {
      dispatch(languageChange(val));
    }
  }

  const handleClickOutside = (event) => {
    if (checkDropdownClick.current && !checkDropdownClick.current.contains(event.target)) {
      setShowHideSelectBoxValue(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Indiapolls - Sign in</title>
      </Helmet>
      <section className="formSec">
        {/* <GridContainer> */}
        <div className="w-100" style={{ position: "fixed", zIndex: "1" }}>
          <div className='w-100 mb-2' style={{ boxShadow: "0px 2px 3px 1px rgba(0, 0, 0, 0.3), 4px 0px 12px 0px rgba(0, 0, 0, 0.3)", zIndex: "1" }}>
            <div className={`card ${window.matchMedia("(max-width: 768px)").matches ? "p-3" : "p-2"}`}>
              <div className="d-flex align-items-center justify-content-center">
                <img src={lang == "hi" ? hindiMainHeaderLogo : signupindiapollsHeader} className="" alt="IndiaPolls" style={{ width: `${window.matchMedia("(max-width: 768px)").matches ? "30%" : "13%"}` }} />
              </div>
            </div>
          </div>
        </div>
        {/* </GridContainer> */}
        <div className="container zoom-70">
          <div className='col-md-5 m-auto'>
            <div>
              <img src={lang == "hi" ? hindiLoginImg : headerImage} className="w-100 customImageCss" alt="IndiaPolls Header" />
            </div>
          </div>
          <div className="row" style={{ paddingTop: '2%' }}>
            {/* <div className="col-md-6">
              <div className="logoDiv">
                <img src={Assets.Logo2} style={{width:'100%'}} className="img-fluid mobileNone" alt="alt" />
                <p className="mobileNone text-center" style={{fontSize: 18 }}>{pageContents.items[10].title}
                <br/>

                </p>

              </div>
            </div> */}
            <div className="col-sm-12 col-md-5 col-lg-5 m-0 m-auto">
              {/* <div>
                <div className="formdesign">
                  <i className="fa fa-info-circle" aria-hidden="true" />{pageContents.title}
                </div>
              </div> */}
              {/* <div className="formdesign2"> */}
              <div className="card card-body pt-1 mb-5 shadow" style={{ backgroundColor: "rgb(250, 250, 250, 0.95)", borderTop: "3rem solid rgb(245 130 50)", borderRadius: "0.75rem" }}>
                {/* <img src={Assets.Logo2} style={{width:'100%'}} className="img-fluid desktopNone" alt="" /> */}
                {/* <h2>{pageContents.items[0].title}</h2> */}
                <h3 style={{ color: "#046A38", fontWeight: "700", opacity: "1", marginBottom: "0px", marginTop: `${lang == "hi" ? "10px" : ""}` }}>{pageContents.items[0].title}</h3>
                <p style={{ color: "rgb(58, 58, 58)", fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "20px" : "16px"}` }}>{pageContents.items[1].title}<Link to="#" onClick={handleClick} className="text-white" style={{ backgroundColor: "#046A38", borderRadius: "0.5rem", textDecoration: "none", padding: `${lang === "hi" ? "0.3rem" : "0.1rem"} 0.5rem 0.3rem 0.5rem` }}>{pageContents.items[2].title}</Link></p>
                <div className="social-login">
                  <div>
                    {/* <img src={'assets/img/google.png'} style={{width:'40px', height:'40px'}} alt="" onClick={handleContinueWithGoogleClick}/> */}
                    {/* &nbsp; &nbsp;  */}
                    <button
                      style={{ width: "100%", borderRadius: "0.9rem", backgroundColor: "#515356", color: "#fff", opacity: "1" }}
                      className="p-2"
                      onClick={handleContinueWithFacebookClick}
                    >
                      <span style={{ fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "25px" : "16px"}` }}>
                        <img src={facebookImage} className="" alt="IndiaPolls Header" style={{ width: "8%" }} />
                        &nbsp;&nbsp;{lang === 'hi' ? "फेसबुक से जुड़ें" : 'Connect with facebook'}
                      </span>
                    </button>
                    {/* <img src="assets/img/facebook.png" style={{width:'40px', height:'40px'}} alt="" onClick={handleContinueWithFacebookClick}/> */}
                  </div>
                  {/**showGoogleSignIn && <GoogleSignIn /> **/}
                  {showFacebookSignIn && <FacebookSignIn />}

                  {/* <div className="RuleWithText">{pageContents.items[3].title}</div> */}
                  <div>
                    <p className="text-center mt-2" style={{ fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "20px" : "16px"}` }}>{lang == "hi" ? "या" : "OR"}</p>
                  </div>
                  {/* <Button onClick={() => setMobile(true)} className="mobile-otp-button mt-2 mb-2">
                    {pageContents.items[9].title}
                  </Button> */}
                  <button
                    type="submit"
                    style={{ width: "100%", borderRadius: "1rem", backgroundColor: "#046A38", color: "#fff", padding: "0.7rem", fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "25px" : "16px"}` }}
                    onClick={() => setMobile(true)}
                  >
                    <span>
                      {pageContents.items[9].title}
                    </span>
                  </button>

                  <MobileLogin
                    referralId={referralId}
                    show={isMobile}
                    onClose={() => setMobile(false)}
                    onSubmit={() => {
                      setMobile(false)
                    }
                    }
                  />

                  {/*<a href="#" onClick={GoogleSignIn}><img src="assets/img/google.svg" alt="" /></a>*/}
                  {/*<FacebookLogin*/}
                  {/*    appId="879890270328649"*/}
                  {/*    autoLoad={false}*/}
                  {/*    fields="name,email,picture"*/}
                  {/*    callback={(e) => responseFacebook(e)}*/}
                  {/*    icon={<img src="/assets/img/facebook.svg" alt="Facebook" />}*/}
                  {/*    cssClass="facebook-login-btn"*/}
                  {/*    textButton=""*/}
                  {/*/>*/}
                </div>
                {/* <div className="RuleWithText">{pageContents.items[3].title}</div> */}
                <div>
                  <p className="text-center mt-2" style={{ fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "20px" : "16px"}` }}>{lang == "hi" ? "या" : "OR"}</p>
                </div>
                <form onSubmit={props.handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    {/* <label htmlFor="email">{pageContents.items[4].title}</label> */}
                    <input
                      data-lang={lang}
                      maxLength={200}
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={(e) => {
                        e.target.setCustomValidity("")
                        setUsername({ username: e.target.value })
                      }
                      }
                      required
                      title={registerDict[lang]['correct_email_format']}
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      onInput={(e) => e.target.setCustomValidity("")}
                      onInvalid={(e) => {
                        let msg = registerDict[lang]['correct_email_format'];
                        e.target.setCustomValidity(msg)
                      }}
                      style={{ border: "1px solid rgb(170, 170, 170)", borderRadius: "0.75rem", fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "25px" : "16px"}` }}
                      placeholder={pageContents.items[4].title}
                    />
                  </div>
                  <div className="mb-5">
                    {/* <label htmlFor="pwd">{pageContents.items[5].title}</label> */}
                    <input data-lang={lang} type="password" className="form-control" name="pswd" onChange={(e) => {
                      e.target.setCustomValidity("")
                      setPassword({ password: e.target.value })
                    }
                    }
                      title={registerDict[lang]['password_required']}
                      onInput={(e) => e.target.setCustomValidity("")}
                      onInvalid={(e) => {
                        let msg = registerDict[lang]['validationMessage'] + ': ' + registerDict[lang]['password_required']
                        e.target.setCustomValidity(msg)
                      }}
                      style={{ border: "1px solid rgb(170, 170, 170)", borderRadius: "0.75rem", fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "25px" : "16px"}` }}
                      placeholder={pageContents.items[5].title}
                      required />
                  </div>
                  {/* <GoogleReCaptcha
                    onCaptchaValidation={handleCaptchaValidation}
                  /> */}
                  <Show when={true}>
                    {/* <a className='frgt_pass' onClick={(e)=>setResetPassword(true)}>
                    {pageContents.items[6].title}
                  </a> */}
                    <a className='' onClick={(e) => setResetPassword(true)} style={{ color: "rgb(245 130 50)", fontWeight: "bold", marginLeft: "1rem", cursor: "pointer", textDecoration: "none", fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "20px" : "16px"}` }}>
                      {pageContents.items[6].title}
                    </a>
                  </Show>
                  <p className="mt-5">
                    <LoadingSpinner show={props.isLoading} />
                    <Show when={true}>
                      {props?.error?.message ?? ''}
                    </Show>
                    <span className="text-right">
                      {/* <button
                      type="submit"
                      style={{float:'right'}}
                      className="btn btn-primary"
                      disabled={submitting || invalid || captcha === false}
                  >
                    {pageContents.items[7].title}
                  </button> */}
                      <button
                        type="submit"
                        style={{ float: 'right', borderRadius: "1.5rem", backgroundColor: "#515356", color: "#fff", paddingLeft: "16px", fontSize: `${window.matchMedia("(max-width: 768px)").matches ? "20px" : "16px"}` }}
                        className=""
                        disabled={submitting || invalid || captcha === false}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {pageContents.items[7].title} &nbsp;<img src={signUpImg} className="" alt="IndiaPolls Header" width={"20rem"} />
                        </span>
                      </button>
                    </span>

                    <div className="mt-3 px-3" onClick={clickOnSelectBox} style={{ width: "30%", display: "flex", justifyContent: "space-evenly", alignItems: "center", border: "1px solid rgb(170, 170, 170)", borderRadius: "0.6rem", cursor: "pointer", position: "relative" }} ref={checkDropdownClick}>
                      <span>{props.language ? (props.language == "en" ? "English" : "हिंदी") : "English"}</span> &nbsp;<img src={arrowDownImg} className="" alt="Arrow Down" width={"15rem"} />
                    </div>
                    <div style={{ position: "absolute", display: `${showHideSelectBoxValue ? "" : "none"}` }} ref={checkDropdownClick}>
                      <div>
                        <p onClick={() => { selectDropdownValue("en") }} style={{ borderTop: "1px solid rgb(170, 170, 170)", margin: "0px", padding: '0px', width: "8rem", textAlign: "center", background: "#fff", borderLeft: "1px solid rgb(170, 170, 170)", borderRight: "1px solid rgb(170, 170, 170)", cursor: "pointer" }}>English</p>
                        <p onClick={() => { selectDropdownValue("hi") }} style={{ border: "1px solid rgb(170, 170, 170)", margin: "0px", padding: '0px', width: "8rem", textAlign: "center", background: "#fff", cursor: "pointer" }}>हिंदी</p>
                      </div>
                    </div>
                    {/* <select id="language-dropdown" className=" text-center" onChange={languageChangeOptions} value={props.language} 
                  style={{
                    border: "1px solid #999999", 
                    borderRadius: "0.5rem", 
                    color: "#666",
                    appearance: "none", 
                    WebkitAppearance: "none", 
                    MozAppearance: "none", 
                    backgroundImage: `url(${arrowDownImg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: `${window.matchMedia("(max-width: 768px)").matches ? "95px" : "80px"}`,
                    backgroundSize: "10px", 
                    width: "25%",
                    fontSize:`${window.matchMedia("(max-width: 768px)").matches ? "20px" : "16px"}`,
                  }}
                >
                  <option value="en">English</option>
                  <option value="hi">हिंदी</option>
                </select> */}
                  </p>
                </form>
                {/* <div className="mt-2 mb-2">
                  <SimpleCaptcha
                  onValid={() => setCaptcha(true)}
                  language={props.language}
                  />
                </div> */}
                <Alert
                  variant="danger"
                  show={props.authError && (props.authError == "User Not found." || props.authError === 'Invalid Password!')}
                >
                  {props.language === 'hi' ? (props.authError === 'User Not found.' ? 'उपयोगकर्ता नहीं मिला।' : props.authError === 'Invalid Password!' ? 'गलत पासवर्ड।' : props.authError) : props.authError}
                </Alert>
                {/* <div className="text-center">
                    <select id="language-dropdown" className="text-center" onChange={languageChangeOptions} value={props.language}>
                    <option value="en">English</option>
                    <option value="hi">Hindi</option>
                    </select>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ForgetPasswordEmailWithState
        show={isPasswordReset}
        onHide={() => setResetPassword(false)}
        onClose={() => setResetPassword(false)}
      />
    </>

  );
}

Login.propTypes = {
  authLogin: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  authError: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const LoginWithRedux = reduxForm({ form: "loginForm" })(Login);

const selector = formValueSelector("loginForm");

const mapStateToProps = (state) => {
  const username = selector(state, "username") || false;
  return {
    isAuth: state.adminUser.adminUser.isAuthenticated,
    token: state.adminUser.adminUser.token,
    state: state,
    language: state.adminUser.adminUser.language,
    isLoading: state.adminUser.adminUser.loading,
    authError: state.adminUser.adminUser.error,
    username,
  };
};

const LoginWithState = connect(mapStateToProps, { authLogin })(LoginWithRedux);

export { Login, LoginWithState };
