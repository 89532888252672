import React, { Component } from 'react';
import {
    Alert, Button, Spinner, Table,
} from 'react-bootstrap';
import { PageStatus } from 'enums';
import {Show} from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import {exportToExcel} from "../../Utils/ExportToExcel";
import {AuthAPI, SamplesAPI} from "../../API";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import Select from 'react-select';


const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    data: any[],
    id?: string | null,
    filteredData: any[],
    filters: {
        userId: '',
        email: '',
        phoneNumber: '',
        loginType: {},
        osOptionArray:any[],
    },
    pageSize : any,
    currentPageNumber : any,
    totalResponseData : any,
    totalResponsePages : any,
    loading: any,
    deviceTargetOptions: any[],
    selectedDeviceOptions: any[],
    selectedOsOptions: any[],
};

export class BasicProfileOnly extends Component<any, State> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            data: [],
            id: null,
            filteredData: [],
            filters: {
                userId: '',
                email: '',
                phoneNumber: '',
                loginType: {
                    "desktop": false,
                    "tablet": false,
                    "mobile": false,
                    "windows": false,
                    "mac": false,
                    "tablet_android": false,
                    "tablet_ios": false,
                    "mobile_android": false,
                    "mobile_ios": false,
                  },
                  osOptionArray:[],
                },
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : '',
            loading: false,
            deviceTargetOptions: [{ label: "Desktop", value: 'desktop' }, { label: "Tablet", value: 'tablet' }, { label: "Mobile", value: 'mobile' }],
            selectedDeviceOptions: [],
            selectedOsOptions: [],
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        this.fetchList()
    }

    fetchList(): Promise<void> {
        const {pageSize, currentPageNumber, filters} = this.state; 
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.usersList(pageSize, currentPageNumber, filters, 'basicProfileOnly'))
            .then((users) => {
                let responseData: any = users;         
                this.setState({ data: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage, status: PageStatus.Loaded });
              })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    isShowDetailModal(): boolean {
        return this.state.formType === MODAL_TYPES.DETAILS
            || this.state.formType === MODAL_TYPES.DELETE;
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        // console.log(name, value);
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value.trim(),
                },
            }),
        );
    };

    applyFilters = () => {
        if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
        this.setState({currentPageNumber : 1})
    };

    clearFilter = async () => {
        await this.setState({ filters: {
                userId: '',
                email: '',
                phoneNumber: '',
                loginType: {
                    "desktop": false,
                    "tablet": false,
                    "mobile": false,
                    "windows": false,
                    "mac": false,
                    "tablet_android": false,
                    "tablet_ios": false,
                    "mobile_android": false,
                    "mobile_ios": false,
                  },
                  osOptionArray:[],
                },
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : '',
            selectedDeviceOptions: [],
            selectedOsOptions: [],
          });
        this.fetchList();
    }

    async handleExport(){
        this.setState({ loading: true });
        const {pageSize, currentPageNumber, filters} = this.state; 
        try {
            const recievedData = await AuthAPI.usersList('All', currentPageNumber, filters, 'basicProfileOnly');
            exportToExcel(recievedData.data, 'BasicProfileOnly');
        } catch (error) {
            console.error("Error exporting data:", error);
        }finally {
            this.setState({ loading: false });
        }
    };

    handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
    handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };
  
    componentDidUpdate(prevProps: {}, prevState: State) {
        if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
    };
    
    handleDeviceFilterChange = async (selectedDeviceOptions) => {
        let checkLastElement = selectedDeviceOptions[selectedDeviceOptions.length-1];
        this.setState((prevValues) => {
            const updatedFilters = {
                "desktop": false,
                "tablet": false,
                "mobile": false,
                "windows": false,
                "mac": false,
                "tablet_android": false,
                "tablet_ios": false,
                "mobile_android": false,
                "mobile_ios": false,
              };
            let osOptionArray: { label: string, value: string }[] = [];
            let prevSelectedOptionsArray: { label: string, value: string }[] = [...prevValues.selectedOsOptions];
            selectedDeviceOptions.forEach((element) => {
                if (element.value === "desktop") {
                    osOptionArray.push({ label: "Windows", value: 'windows' });
                    osOptionArray.push({ label: "Mac", value: 'mac' });
                    if(checkLastElement.label == "Desktop" && prevSelectedOptionsArray.find(ele=> ele.value == "windows") == undefined && prevSelectedOptionsArray.find(ele=> ele.value == "mac") == undefined){
                        prevSelectedOptionsArray.push({ label: "Windows", value: 'windows' });
                        prevSelectedOptionsArray.push({ label: "Mac", value: 'mac' });
                    }
                }
                if (element.value === "tablet") {
                    osOptionArray.push({ label: "Tablet Android", value: 'tablet_android' });
                    osOptionArray.push({ label: "Tablet iOS", value: 'tablet_ios' });
                    if(checkLastElement.label == "Tablet" && prevSelectedOptionsArray.find(ele=> ele.value == "tablet_android") == undefined && prevSelectedOptionsArray.find(ele=> ele.value == "tablet_ios") == undefined){
                        prevSelectedOptionsArray.push({ label: "Tablet Android", value: 'tablet_android' });
                        prevSelectedOptionsArray.push({ label: "Tablet iOS", value: 'tablet_ios' });
                    }
                }
                if (element.value === "mobile") {
                    osOptionArray.push({ label: "Mobile Android", value: 'mobile_android' });
                    osOptionArray.push({ label: "Mobile iOS", value: 'mobile_ios' });
                    if(checkLastElement.label == "Mobile" && prevSelectedOptionsArray.find(ele=> ele.value == "mobile_android") == undefined && prevSelectedOptionsArray.find(ele=> ele.value == "mobile_ios") == undefined){
                        prevSelectedOptionsArray.push({ label: "Mobile Android", value: 'mobile_android' });
                        prevSelectedOptionsArray.push({ label: "Mobile iOS", value: 'mobile_ios' });
                    }
                }
                if (updatedFilters[element.value] != undefined) {
                    updatedFilters[element.value] = true;
                } 
            });
            if(selectedDeviceOptions.find(ele=> ele.value == "desktop") == undefined){
                prevSelectedOptionsArray = prevSelectedOptionsArray.filter((ele) => (ele.value !== "windows" && ele.value !== "mac"));
            }
            if(selectedDeviceOptions.find(ele=> ele.value == "tablet") == undefined){
                prevSelectedOptionsArray = prevSelectedOptionsArray.filter(ele => ele.value !== "tablet_android" && ele.value !== "tablet_ios");
            }
            if(selectedDeviceOptions.find(ele=> ele.value == "mobile") == undefined){
                prevSelectedOptionsArray = prevSelectedOptionsArray.filter(ele => ele.value !== "mobile_android" && ele.value !== "mobile_ios");
            }
            prevSelectedOptionsArray.forEach(element=> updatedFilters[element.value] = true);
            return {
                filters: {
                    ...prevValues.filters,
                    loginType: updatedFilters,
                    osOptionArray
                },
                selectedDeviceOptions,
                selectedOsOptions: prevSelectedOptionsArray,
            };
        });
    };

    handleOsFilterChange = (selectedOsOptions)=>{
        this.setState((prevValues) => {
            const selectedDeviceOptions:any = [];
            let dummyArr:any[] = [];
            const updatedFilters = { 
                "desktop": false,
                "tablet": false,
                "mobile": false,
                "windows": false,
                "mac": false,
                "tablet_android": false,
                "tablet_ios": false,
                "mobile_android": false,
                "mobile_ios": false,
            };
            selectedOsOptions.forEach((element)=>{ 
                updatedFilters[element.value] = true;
                if(element.value === "windows" || element.value === "mac"){ updatedFilters["desktop"] = true; selectedDeviceOptions.push({ label: "Desktop", value: 'desktop' }) } 
                if(element.value === "tablet_android" || element.value === "tablet_ios"){ updatedFilters["tablet"] = true; selectedDeviceOptions.push({ label: "Tablet", value: 'tablet' })} 
                if(element.value === "mobile_android" || element.value === "mobile_ios"){ updatedFilters["mobile"] = true; selectedDeviceOptions.push({ label: "Mobile", value: 'mobile' })} 
                dummyArr = selectedDeviceOptions.filter( ele => updatedFilters[ele.value]);
                dummyArr = [...new Map(selectedDeviceOptions.map(item => [`${item.label}-${item.value}`, item])).values()];
            });
            return {
                filters: {
                    ...prevValues.filters,
                    loginType: updatedFilters,
                },
                selectedOsOptions,
                selectedDeviceOptions : dummyArr,
            };
        });
    }

    render() {
        const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Basic Profile Panelists</h4>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='mb-3'>Filter</div>

                    <form>
                        <div className="row">
                            <div className="col">
                                <label>User ID</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="userId"
                                       value={filters.userId}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Email</label>
                                <input type="email"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="email"
                                       value={filters.email}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                            <div className="col">
                                <label>Phone Number</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter..."
                                       name="phoneNumber"
                                       value={filters.phoneNumber}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                        </div>
                        <div className='card mt-3' style={{backgroundColor:"#e8e8e8"}}>
                            <div className='row p-3'>
                                <div className="col">
                                    <label>Select Device</label>
                                    <Select
                                        name='loginType'
                                        id='loginType'
                                        onChange={(e) => {this.handleDeviceFilterChange(e)}}
                                        value={this.state.selectedDeviceOptions}
                                        isMulti
                                        required
                                        options={this.state.deviceTargetOptions}
                                    />
                                </div>
                                <div className="col">
                                    <label>Select OS</label>
                                    <Select
                                        name='loginType'
                                        id='loginType'
                                        onChange={(e) => {this.handleOsFilterChange(e)}}
                                        value={this.state.selectedOsOptions}
                                        isMulti
                                        required
                                        options={this.state.filters?.osOptionArray}
                                        isDisabled={this.state.filters?.osOptionArray?.length < 1}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                        <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Panelists</button>
                        <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
                        <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
                    </div>


                </div>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>

                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.filteredData.length}>
                                No data is available
                            </Alert>
                        </Show>

                        <Show when={!!this.state.filteredData.length}>
                            <div className='mb-2'>  
                                <ShowDataAccording2PageSize 
                                currentPageSize={pageSize} 
                                currentPage={currentPageNumber}
                                sendPageSize={this.handlePageSizeChange}
                                showTotalData={totalResponseData} 
                                sendLastPage={totalResponsePages}
                                />
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                                {this.state.loading && (
                                    <img style={{ height: "415px", }}
                                        src="../loadernew.gif"
                                        alt="Loading..."
                                    />
                                )}
                            </div>
                            <Table responsive size="sm" bordered>
                                <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>User Id</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Date Of Birth</th>
                                    <th>CreatedAt</th>
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    this.state.filteredData.map((info, index) => (
                                        <tr key={info.id}>
                                            {/* <td>{this.state.filteredData.length - index}</td> */}
                                            <td>{((pageSize)*(currentPageNumber-1))+(index + 1)}</td>                                            <td>{info.id}</td>
                                            <td>
                                              <span
                                                  aria-label="button"
                                                  role="button"
                                                  tabIndex={0}
                                                  className="text-primary"
                                                  onKeyPress={() => null}
                                                  onClick={() => {
                                                      this.setState({
                                                          formType: MODAL_TYPES.NONE,
                                                          id: info.id,
                                                      });
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                      __html: `${info.firstName} ${info.lastName}` || '-',
                                                  }}
                                              />
                                            </td>
                                            <td>{info.email}</td>
                                            <td>{info.phoneNumber}</td>
                                            <td>{info.state}</td>
                                            <td>{info.city}</td>
                                            <td>{moment(info.dateOfBirth).format('MM/DD/YYYY')}</td>
                                            <td>{moment(info.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>

                            </Table>
                            <div>
                                <PaginationNumbering 
                                currentPage={currentPageNumber} 
                                returnPagenumber={this.handlePageNumberChange}
                                showTotalData={totalResponseData} 
                                sendLastPage={totalResponsePages}
                                />
                            </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}
