import * as _ from 'lodash';

import { api } from '../axiosConfig';
import {whatsappTemp} from "../Utils/urls";

export class WhatsappTempAPI {
    static async create (
        payload: { userAuthId: string; } & any,
    ): Promise<any[]>{
        return await api.post(`${whatsappTemp}/create`, payload).then((res) => {
            return _.get(res, 'data', []);
        });
    }

    static async getAll(
        limit : any,
        pagenumber: number,
        payload: { userAuthId: string; } & any,
    ): Promise<any[]> {
        return await api.post(`${whatsappTemp}/getAll/${limit}/${pagenumber}`, payload).then((res) => {     
            return _.get(res, 'data', []);
        });
    }   
    
    static async update (
        id: any,
        payload: { userAuthId: string; } & any,
    ): Promise<any[]> {
        return await api.post(`${whatsappTemp}/updatedetails/${id}`, payload).then((res)=>{
            return _.get(res, 'data', []);
        });
    }

    static async delete (
        id: string,
    ): Promise<any[]>{
        return await api.post(`${whatsappTemp}/delete/${id}`, {}).then((res)=>{
            return _.get(res, "data", []);
        });
    }

    static async getById (
        id: string,
    ): Promise<any[]>{
        return await api.post(`${whatsappTemp}/getById/${id}`, {}).then((res)=>{
            return _.get(res, "data", []);
        });
    }
}
