import * as _ from 'lodash';

import { api } from '../axiosConfig';
import { profileManagementURL, questionsURL, optionsURL, userURL } from "../Utils/urls";

export class ProfileManagementAPI {
    static getAll(
        limit = 10000,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${profileManagementURL}/getAll/${limit}`, {})
        .then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static create(values: any): Promise<any> {
        const data = {
            name: values.name,
            description: values.description,
            displayOrder: values.displayOrder,
        };
        return api.post(`${profileManagementURL}/create`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static update(values: any, id: any): Promise<any> {
        const data = {
            name: values.name,
            description: values.description,
            displayOrder: values.displayOrder,
        };
        return api.post(`${profileManagementURL}/update/${id}`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static getOne(id: any): Promise<any> {
        return api.post(`${profileManagementURL}/getOne/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getOneDetails(id: any, userId: any): Promise<any> {
        return api.post(`${profileManagementURL}/getOneDetails/${id}/${userId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static respondentProfileOverview(userId: any): Promise<any> {
        return api.post(`${userURL}/respondentProfileOverview/${userId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static deleteOne(id: any): Promise<any> {
        return api.post(`${profileManagementURL}/delete/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static createUserProfile(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${profileManagementURL}/createUserProfilesweb`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    //Questions

    static getAllQuestions(
        limit = 10000,
        id: string,
    ): Promise<any[]> {
        return api.post(`${questionsURL}/getAll/${id}/${limit}`, {})
        .then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static createQuestions(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${questionsURL}/create`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static updateQuestions(payload: { userAuthId: string; } & any, id: any): Promise<any> {
        return api.post(`${questionsURL}/update/${id}`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static getOneQuestions(id: any): Promise<any> {
        return api.post(`${questionsURL}/getOne/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static getQuestionOptions(questionId: any): Promise<any> {
        return api.post(`${questionsURL}/getQuestionOptions/${questionId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', []);
            });
    }

    static deleteOneQuestions(id: any): Promise<any> {
        return api.post(`${questionsURL}/delete/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static deleteOneOption(id: any): Promise<any> {
        return api.post(`${optionsURL}/delete/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getAllMAOptions(): Promise<any> {
        return api.post(`${optionsURL}/getMaOptions`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getOptionDetails(id: any): Promise<any> {
        return api.post(`${optionsURL}/getOne/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

}
